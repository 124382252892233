import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useCookie, useRuntimeConfig, useNuxtApp } from '#app';
import type { CookieRef } from '#app';
import { isNonNullish } from 'remeda';
import { AuthTab } from '~/data/domain/auth.entity';
import {
  COOKIE_CONSENT_TOKEN,
  DEFAULT_LOCALE_ID,
  DEFAULT_LOCALE_ID_COOKIE,
} from '~/data/domain/constants';
import type { AppLocale } from '~/data/domain/app.locale';
import {
  CONSENT_COOKIE_DURATION_DAYS,
  LOCALE_COOKIE_DURATION_DAYS,
} from '~/shared/constants/cookie-duration';
import { typedLocales } from '~/data/domain/app.locale';

export const useAppStore = defineStore('app', () => {
  const { $i18n } = useNuxtApp();
  const config = useRuntimeConfig();

  const authDialog = ref({
    isVisible: false,
    activeTab: AuthTab.login,
  });
  const submitDialog = ref({
    isVisible: false,
  });
  const customerService = ref({
    isVisible: false,
  });
  const cookieConsent: CookieRef<boolean> = useCookie(COOKIE_CONSENT_TOKEN, {
    default: () => false,
    maxAge: CONSENT_COOKIE_DURATION_DAYS * 24 * 60 * 60,
  });
  const localeIdCookie: CookieRef<number> = useCookie(DEFAULT_LOCALE_ID_COOKIE, {
    default: () => DEFAULT_LOCALE_ID,
    maxAge: LOCALE_COOKIE_DURATION_DAYS * 24 * 60 * 60,
    domain: config.public.rootDomain,
  });
  const locale = computed<AppLocale>(() => $i18n.locale.value);

  watch(
    locale,
    (val: AppLocale) => {
      localeIdCookie.value =
        typedLocales.find((locale) => locale.code === val)?.id || DEFAULT_LOCALE_ID;
    },
    {
      immediate: true,
    },
  );

  const isCookiesNotificationShown = computed(() => !cookieConsent.value);

  // todo: add default timezone
  // const timezone = ref<string | null>(null);

  function toggleAuthDialog(activeTab: AuthTab = AuthTab.login, isVisible?: boolean): void {
    const isDialogVisible = isNonNullish(isVisible) ? isVisible : !authDialog.value.isVisible;

    authDialog.value = {
      isVisible: isDialogVisible,
      activeTab,
    };
  }

  function toggleSubmitDialog(isVisible?: boolean): void {
    const isDialogVisible = isNonNullish(isVisible) ? isVisible : !submitDialog.value.isVisible;

    submitDialog.value = {
      isVisible: isDialogVisible,
    };
  }

  function showCustomerService(isVisible?: boolean): void {
    const isCustomerServiceVisible = isNonNullish(isVisible)
      ? isVisible
      : !customerService.value.isVisible;

    customerService.value = {
      isVisible: isCustomerServiceVisible,
    };
  }

  function setCookieConsent() {
    cookieConsent.value = true;
  }

  return {
    authDialog,
    submitDialog,
    toggleAuthDialog,
    toggleSubmitDialog,
    isCookiesNotificationShown,
    setCookieConsent,
    locale,
    localeIdCookie,
    showCustomerService,
    customerService,
  };
});
