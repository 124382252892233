import locales from '~/assets/languages.json';
import { DEFAULT_LOCALE_ID } from '~/data/domain/constants';

export interface Locale {
  id: number;
  name: string;
  code: string;
}

export const typedLocales = locales as Locale[];

export type AppLocale = (typeof typedLocales)[number]['code'];

export function getLocaleId(locale: AppLocale): number {
  return locales.find((item: Locale) => item.code === locale)?.id || DEFAULT_LOCALE_ID;
}
